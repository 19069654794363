<template>
 <div>
 2222222222
 </div>
</template>
<script>
export default {
name: '',
 data() {
 return {
 
 };
 },
}
</script>

<style lang='less' scoped>

</style>
