<template>
  <div class="dinding">
    <Left></Left>
    <div class="right">
      <div class="content">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">绑定销售/燃料公司</el-menu-item>
          <el-menu-item index="2">绑定的煤炭/电厂</el-menu-item>
        </el-menu>

        <div>
          <div class="flexs dings">
            <div class="flex">
              <el-input
                v-model="config.initiativeName"
                placeholder="煤矿/电厂名称"
              ></el-input>
              <el-input
                style="margin-left: 20px"
                v-model="config.passivityName"
                placeholder="销售/燃料公司名称"
              ></el-input>
            </div>
            <div class="flex">
              <el-button type="primary" @click="inquire" :disabled="status != 3"
                >查询</el-button
              >
              <el-button type="info" @click="resetting" :disabled="status != 3"
                >重置</el-button
              >
            </div>
          </div>

          <div class="dinglist">
            <el-button
              type="primary"
              plain
              @click="addClick"
              v-if="activeIndex == 1"
              :disabled="status != 3"
              >新增</el-button
            >
            <div style="margin-top: 20px">
              <el-table :data="tableData" style="width: 100%" border>
                <el-table-column
                  prop="statusName"
                  label="审核状态"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="passivityName"
                  label="销售/燃料公司名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="initiativeNameTwo"
                  label="煤矿/电厂名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="activeIndex == 1"
                  prop="cachetFileName"
                  label="加盖公章的说明材料"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="createTime" label="时间" align="center">
                </el-table-column>
              </el-table>
            </div>
            <div style="text-align: right">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="10"
                layout="total, prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>

            <div>
              <div class="remarks">
                <div>
                  煤矿企业只需要绑定所属集团销售公司,用煤企业只需要绑定所属集团燃料公司,请勿绑定其他外部贸易企业。
                </div>
                <div>
                  销售公司或燃料公司请勿绑定煤矿企业或用煤企业,只能被绑定。
                </div>
                <div>贸易企业之间请勿互相绑定</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="绑定销售/燃料公司"
      center
      :visible.sync="dialogVisible"
      width="30%"
      destroy-on-close
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item
            label="企业名称"
            label-width="80px"
            prop="enterpriseName"
          >
            <el-autocomplete
              v-model="form.enterpriseName"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelects"
              placeholder="请输入企业名称"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            label="加盖公章的说明材料"
            label-width="155px"
            prop="url"
          >
            <el-upload
              class="upload-demo flex"
              action="#"
              multiple
              :limit="1"
              :http-request="handleExceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button size="small" type="primary">下载模板</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addForm('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findEnterpriseBindingList,
  ossUploadFile2,
  addAndEditEnterpriseBinding,
  findContractTemplateList,
  userList,
  getEnterpriseAuthInfo,
} from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      currentPage1: 1,
      total: 0,
      type: false,
      dialogVisible: false,
      status: false,
      activeIndex: "1",
      tableData: [],
      input: "",
      config: {
        pageNum: 1,
        pageSize: 10,
        initiativeName: "",
        passivityName: "",
        whetherPassivity: true,
      },
      form: {
        enterpriseName: "",
        url: "",
        currentEnterpriseName: "",
      },
      rules: {
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        url: [
          {
            required: true,
            message: "请上传加盖公章的说明材料",
            trigger: "change",
          },
        ],
      },
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  mounted() {
    this.getinfo();
    this.getlist();
    this.status = Vue.ls.get(types.status);
    this.form.currentEnterpriseName = window.localStorage.getItem("userName");
  },
  methods: {
    handleSizeChange(e) {
      console.log(e, "1111111111111");
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
    },
    getinfo() {
      getEnterpriseAuthInfo().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.type = res.data.type;
        }
      });
    },
    handleExceed(file) {
      console.log(file.file);
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile2(formData).then((res) => {
        if (res.code == 0) {
          this.form.url = res.data.fileId;
        }
      });
    },
    inquire() {
      this.getlist();
    },
    resetting() {
      this.config.initiativeName = "";
      this.config.passivityName = "";
      this.getlist();
    },
    addForm(formName) {
      let config = this.form;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addAndEditEnterpriseBinding(config).then((res) => {
            if (res.code == 0) {
              this.dialogVisible = false;
              this.getlist();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getlist() {
      findEnterpriseBindingList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    querySearchAsync(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.form.enterpriseName,
      };
      var list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    handleSelects(item) {
      console.log(item);
      this.form.enterpriseName = item.realName;
      this.form.enterpriseId = item.userId;
       this.$refs.form.validateField("enterpriseName");
    },
    handleSelect(key) {
      console.log(key);
      this.activeIndex = key;
      if (key == 2) {
        this.config.whetherPassivity = false;
      } else {
        this.config.whetherPassivity = true;
      }
      this.getlist();
    },
    addClick() {
      if (this.type == 1 || this.type == 2) {
        this.dialogVisible = !this.dialogVisible;
        this.form.enterpriseName = "";
        this.form.url = "";
        this.form.currentEnterpriseName = "";
      } else {
        this.$message.error("当前账户认证的企业类别没有授权权限");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dinglist {
  padding-top: 20px;
}

.remarks {
  color: red;
}

.dinding {
  width: 1196px;
  margin: 0 auto;
  display: flex;

  .right {
    width: 970px;

    .content {
      padding: 20px;
      background: #fff;
      min-height: 660px;
      margin-top: 20px;
      border: 1px solid #ccc;
    }
  }
}

.dings {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}

.flex {
  display: flex;
}

.flexs {
  display: flex;
  justify-content: space-between;
}
</style>