<template>
  <div class="temp">
    <Left></Left>
    <div class="right">
      <div class="content">
        <div class="">
          <div class="Line"></div>
          <div class="btn">
            <!-- <input
              type="text"
              v-model="config.name"
              placeholder="请输入模板名称"
            /> -->
            <el-autocomplete
                v-model="config.name"
                :fetch-suggestions="querySearchAsync"
                @select="handleSelect"
                placeholder="请输入模板名称"
              >
              </el-autocomplete>
            <div>
              <el-button type="primary" @click="CXclick" :disabled='status!=3'>查询</el-button>
              <el-button type="info" @click="ResetClick" :disabled='status!=3'>重置</el-button>
            </div>
          </div>
          <div class="Line"></div>
        </div>

        <div class="formData">
          <div class="title">产运需运力合同模板</div>
          <div>
            <div>
              <el-button type="primary" plain @click="AddTemplate"  :disabled='status!=3'
                >新增</el-button
              >
              <el-button type="danger" plain @click="DelClick"  :disabled='status!=3||total==0'>删除</el-button>
            </div>
            <div style="margin-top: 20px">
              <el-table
                border
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection"> </el-table-column>

                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)"
                      >编辑</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="模板名称">
                </el-table-column>
                <el-table-column label="录入时间" align="center">
                  <template slot-scope="scope">{{
                    scope.row.createTime
                  }}</template>
                </el-table-column>
              </el-table>
              <div class="pageina">
                <el-pagination
                layout="total, prev, pager, next"
                 @current-change="handleCurrentChange"
                :total="total"
                :page-size="config.pageSize"
                :current-page="config.pageNum"
              >
              </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findContractTemplateList, delContractTemplate } from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      total: 0,
      name: "",
      tableData: [],
      multipleSelection: [],
      config: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      status:false
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  mounted() {
    this.getlist();
    this.status = Vue.ls.get(types.status);
  },
  methods: {
    handleCurrentChange(e){
      this.config.pageNum=e
      this.getlist();
    },
    querySearchAsync(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        name: this.config.name,
      };
      var list = [];
      findContractTemplateList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.name; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    handleSelect(item) {
      console.log(item);
      this.config.name=item.name
    },
    getlist() {
      findContractTemplateList(this.config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        path: "/AddTemplate",
        query: {
          id: row.id,
        },
      });
    },
    CXclick() {
      this.getlist();
    },
    ResetClick() {
      this.config.name = "";
      this.getlist();
    },
    handleSelectionChange(val) {
      let list = [];
      val.forEach((item) => {
        list.push(item.id);
      });

      this.multipleSelection = list;
    },
    DelClick() {
      console.log(this.multipleSelection);
      let config = {
        ids: this.multipleSelection,
      };
      
      delContractTemplate(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getlist();
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    AddTemplate() {
      this.$router.push({
        path: "/AddTemplate",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.temp {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .content {
      padding: 20px;
      background: #fff;
      min-height: 660px;
      margin-top: 20px;
      border: 1px solid #ccc;
    }
  }
}
.Line {
  text-align: center;
  margin: 0 auto;
  height: 1px;
  background: #ccc;
}
.btn {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  input {
    border: 1px solid #ccc;
    padding-left: 10px;
    width: 300px;
  }
}
.formData {
  margin-top: 30px;
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
  }
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
</style>