<template>
  <div class="confirm">
    <Left></Left>
    <div class="right">
      <div class="conter">
        <div class="Line"></div>
        <div class="flex btn">
          <el-input
            v-model="config.no"
            placeholder="请输入合同编号"
            style="margin-left: 20px"
          ></el-input>
          <el-input
            v-model="config.sellerName"
            placeholder="请输入出卖人"
            style="margin-left: 20px"
          ></el-input>
          <el-input
            v-model="config.buyerName"
            placeholder="请输入买受人"
            style="margin-left: 20px"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="CXClick"
            :disabled="status != 3"
            >查询</el-button
          >
          <el-button type="info" @click="ResetClick" :disabled="status != 3"
            >重置</el-button
          >
        </div>
        <div class="Line"></div>
        <div>
          <div class="title">2022年度煤炭中长期合同确认列表</div>
          <div class="flex remarks">
            <div>
              <!-- <el-button type="primary" plain @click="confirmClick"
                >确认</el-button
              >
              <el-button type="danger" plain>驳回</el-button> -->
            </div>
            <div style="color: #ccc">
              合同价格（单位：元/吨）、合同数量（单位：万吨）
            </div>
          </div>
        </div>

        <div style="margin: 20px auto">
          <el-table border :data="tableData" style="width: 100%">
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.row, 1)"
                  :disabled="status != 3"
                  v-if="!scope.row.whetherConfirm"
                  >确认</el-button
                >
                <el-button
                  size="mini"
                  @click="handleEdits(scope.row, 2)"
                  :disabled="status != 3"
                  v-if="!scope.row.whetherConfirm"
                  >驳回</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="履约承诺书" width="300" align="center">
              <template slot-scope="scope">
                <div
                  v-if="
                    !scope.row.whetherLetterUrl ||
                    !scope.row.whetherOtherPartyLetterUrl
                  "
                  style="display: flex; justify-content: space-between"
                >
                  <el-button
                    size="mini"
                    @click="TemplateDownload"
                    :disabled="status != 3"
                    >模板下载</el-button
                  >
                  <!-- <el-button size="mini" @click="Templateup">上传</el-button> -->
                  <el-upload
                    style="margin: 0 10px"
                    class="upload-demo"
                    action="#"
                    multiple
                    :limit="1"
                    :before-upload="
                      (file) => handleImageBeforeUp(file, scope.row)
                    "
                    :on-change="handleChange"
                  >
                    <el-button size="small" :disabled="status != 3"
                      >上传</el-button
                    >
                  </el-upload>
                  <el-button size="mini" :disabled="status != 3"
                    >在线签章签订</el-button
                  >
                </div>
                <div v-else>已上传</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="no"
              label="合同编号"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <div @click="NoClick(scope.row)" style="cursor: pointer">
                  {{ scope.row.no }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="合同状态" width="120" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 1)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.status | status }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="审核状态" width="120" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 2)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.auditStatus | auditStatus }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="sellerName"
              label="出卖人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buyerName"
              label="买受人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractType"
              label="合同类型"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractType | contractType }}
              </template>
            </el-table-column>
            <el-table-column
              prop="Enter"
              label="是否补录"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractForm"
              label="合同形式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractForm | contractForm }}
              </template>
            </el-table-column>
            <el-table-column
              prop="basePrice"
              label="合同基础价格"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="typeOfShipping"
              label="运输方式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.typeOfShipping | typeOfShipping }}
              </template>
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="合同起始日期"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="合同结束日期"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="positionName"
              label="签订地点"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="departure"
              label="发局"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="arrive"
              label="到局"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="pageina">
            <el-pagination
              layout="total, prev, pager, next"
              @current-change="handleCurrentChange"
              :total="total"
              :page-size="config.pageSize"
              :current-page="config.pageNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="驳回理由"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div>
        <div style="color: red"><span>*</span> 驳回理由</div>
        <div style="margin-top: 20px">
          <el-input
            v-model="input"
            :rows="2"
            type="textarea"
            placeholder="请输入驳回理由"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibleClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findContractList,
  editContractStatus,
  getFileIdByType,
  editContractFile,
} from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
const { baseUrl } = require("@/config");
import { ossUploadFiletwo } from "@/api/public.js";
export default {
  data() {
    return {
      baseUrl: baseUrl,
      numberVal: "",
      SellerVal: "",
      BuyerVal: "",
      total: 0,
      tableData: [],
      config: {
        pageNum: 1,
        pageSize: 10,
        type: 2,
        no: "",
        sellerName: "",
        buyerName: "",
      },
      multipleSelection: [],
      centerDialogVisible: false,
      input: "",
      reId: null,
      status: null,
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  filters: {
    status(val) {
      let _data = {
        1: "需方待确认",
        2: "供方待确认",
        3: "合同确认",
        4: "需方已驳回",
        5: "供方已驳回",
      };
      return _data[val];
    },
    auditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
        3: "已通过",
      };
      return _data[val];
    },
    typeOfShipping(val) {
      let _data = {
        1: "铁水联运（国铁）",
        2: "铁路直达（国铁）",
        3: "自有铁路",
        4: "公路运输",
        5: "其他运输",
      };
      return _data[val];
    },
    contractType(val) {
      let _data = {
        1: "年度中长期合同",
        2: "进口煤应急保障合同",
        3: "重点贸易商保供合同",
      };
      return _data[val];
    },
    contractForm(val) {
      let _data = {
        1: "跨省衔接",
        2: "省内交易",
      };
      return _data[val];
    },
  },
  mounted() {
    this.status = Vue.ls.get(types.status);
    this.getlist();
  },
  methods: {
    StatusClick(e, val) {
      console.log(e.row);
      if (val == 1) {
        if (e.row.reason != null && e.row.reason != "") {
          this.$message.error(e.row.reason);
        }
      }
      if (val == 2) {
        if (e.row.auditReason != null && e.row.auditReason != "") {
          this.$message.error(e.row.auditReason);
        }
      }
    },
    Templateup() {
      // editContractFile
    },
    NoClick(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
          type: 1,
        },
      });
    },
    handleChange() {},
    handleImageBeforeUp(file, row) {
      let otherFiles = file;
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          let config = {
            fileUrl: res.data.fileId,
            id: row.id,
          };
          editContractFile(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "上传成功",
                type: "success",
              });
              this.getlist();
            }
          });
        }
      });
    },
    TemplateDownload() {
      let config = {
        type: 1,
      };
      getFileIdByType(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let id = res.data.id;
          let newurl = this.baseUrl + "third/third/ossGetFile4/" + id;
          const a = document.createElement("a");
          // 给a标签的href属性值加上地址,注意:这里是绝对路径,不用加 点.
          a.href = newurl;
          // 设置下载文件文件名,这里加上.xlsx指定文件类型,pdf文件就指定.fpd即可
          // a.download = row.title;
          // 障眼法藏起来a标签
          a.style.display = "none";
          // 将a标签追加到文档对象中
          document.body.appendChild(a);
          // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
          a.click();
          // 一次性的,用完就删除a标签
          a.remove();
        }
      });
    },
    getlist() {
      findContractList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    CXClick() {
      this.getlist();
    },
    handleEdit(row, type) {
      console.log(row, type);
      if (row.whetherLetterUrl && row.whetherOtherPartyLetterUrl) {
        this.$confirm("是否确认合同?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let config = {
              id: row.id,
              type: type,
            };
            editContractStatus(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "确认合同成功",
                  type: "success",
                });
                this.getlist();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消确认合同",
            });
          });
        return false;
      } else {
        this.$message({
          message: "请先上传/在线签订履约承诺书!",
          type: "warning",
        });
      }
    },
    handleEdits(row, type) {
      this.reId = row.id;
      this.centerDialogVisible = true;
    },
    centerDialogVisibleClick() {
      let config = {
        reason: this.input,
        type: 2,
        id: this.reId,
      };
      editContractStatus(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "驳回成功",
            type: "success",
          });
          this.centerDialogVisible = false;
          this.getlist();
        }
      });
    },
    ResetClick() {
      this.config = {
        pageNum: 1,
        pageSize: 10,
        type: 2,
        no: "",
        sellerName: "",
        buyerName: "",
      };
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.remarks {
  margin-top: 20px;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: center;
  margin: 30px auto 0;
  font-size: 20px;
}
.Line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.btn {
  margin: 20px 0;
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
</style>