<template>
  <div class="withdrawalRecord">
    <p class="title">提现记录</p>
    <el-table
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="table.list"
        border
        style="width: 100%"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="申请提现时间" prop="a"></el-table-column>
        <el-table-column label="提现金额" prop="b"></el-table-column>
        <el-table-column label="提现银行" prop="c"></el-table-column>
        <el-table-column label="提现状态" prop="d"></el-table-column>
        <el-table-column label="剩余金额" prop="d"></el-table-column>
      </el-table>
      <el-pagination
        :handleSizeChange="handleSizeChange"
        :handleCurrentChange="handleCurrentChange"
        :currentPage="table.currentPage"
        :size="table.size"
        :total="table.total"
      ></el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      table: {
        size: 10,
        total: 100,
        currentPage: 1,
        list: [
          {a:"阿萨德", b:'111',c:"阿萨德", d:'111'}
        ]
      }
    }
  },
  methods: {
    handleSizeChange () {},
    handleCurrentChange () {},
  }
}
</script>

<style lang="scss" scoped>
.withdrawalRecord{
  margin:0 auto;
  margin-top: 20px;
  margin-bottom: 18px;
  background-color:#ffffff;padding-bottom: 10px;
  width: 1195px;
}
.el-table{
  min-height: 500px;
}
.el-pagination{
  text-align: right;
}
.title{
  font-size: 20px;
  padding: 10px;
}
</style>