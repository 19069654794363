<template>
  <div class="enter">
    <Left></Left>
    <div class="right">
      <div class="content">
        <div class="Line"></div>
        <div class="title">2022年度煤炭中长期合同录入列表</div>
        <div class="btn">
          <div>
            <el-button
              type="primary"
              plain
              @click="AddEnter"
              :disabled="status != 3"
              >新增</el-button
            >
            <el-button
              type="danger"
              plain
              @click="delClick"
              :disabled="status != 3||total==0"
              >删除</el-button
            >
          </div>
          <div style="color: rgb(161, 161, 161)">
            合同价格(单位:元/吨),合同数量(单位:万吨)
          </div>
        </div>
        <div style="margin: 20px auto">
          <el-table
            border
            :data="tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.row)"
                  :disabled="scope.row.status != 4&&scope.row.status != 5&&scope.row.auditStatus!=2"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="no"
              label="合同编号"
              width="150"
              align="center"
            >
            <template slot-scope="scope">
                <div @click="NoClick(scope.row)" style="cursor: pointer">
                  {{ scope.row.no }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="合同状态" width="120" align="center">
             <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 1)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.status | status }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="审核状态" width="200" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 2)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.auditStatus | auditStatus }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="sellerName"
              label="出卖人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buyerName"
              label="买受人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractType"
              label="合同类型"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractType | contractType }}
              </template>
            </el-table-column>
            <el-table-column
              prop="Enter"
              label="是否补录"
              width="120"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="contractForm"
              label="合同形式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractForm | contractForm }}
              </template>
            </el-table-column>
            <el-table-column
              prop="basePrice"
              label="合同基础价格"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="typeOfShippingLabel"
              label="运输方式"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="合同起始日期"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="合同结束日期"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="positionName"
              label="签订地点"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="departure"
              label="发局"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="arrive"
              label="到局"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="pageina">
            <el-pagination
                layout="total, prev, pager, next"
                 @current-change="handleCurrentChange"
                :total="total"
                :page-size="config.pageSize"
                :current-page="config.pageNum"
              >
              </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findContractList, delContract } from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      total: 0,
      name: "",
      tableData: [],
      multipleSelection: [],
      status: null,
      config : {
        type: 1,
        pageNum: 1,
        pageSize: 10,
      }
    };
  },
  mounted() {
    this.status = Vue.ls.get(types.status);
    this.getlist();
  },
  filters: {
    status(val) {
      let _data = {
        1: "需方待确认",
        2: "供方待确认",
        3: "合同确认",
        4: "需方已驳回",
        5: "供方已驳回",
      };
      return _data[val];
    },
    auditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
        3: "已通过",
      };
      return _data[val];
    },
    contractType(val) {
      let _data = {
        1: "年度中长期合同",
        2: "进口煤应急保障合同",
        3: "重点贸易商保供合同",
      };
      return _data[val];
    },
    contractForm(val) {
      let _data = {
        1: "跨省衔接",
        2: "省内交易",
      };
      return _data[val];
    },
  },
  methods: {
    NoClick(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
          type:1
        },
      });
    },
    handleSelectionChange(val) {
      let list = [];
      val.forEach((item) => {
        list.push(item.id);
      });
      this.multipleSelection = list;
    },
    StatusClick(e,val) {
      console.log(e.row)
      if (val == 1) {
        if (e.row.reason != null&&e.row.reason !='') {
          this.$message.error(e.row.reason);
        }
      }
      if(val==2){
        if (e.row.auditReason != null&&e.row.auditReason !='') {
          this.$message.error(e.row.auditReason);
        }
      }
    },
    delClick() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择删除的数据",
          type: "error",
        });
        return false;
      }
      console.log(this.multipleSelection);
      let config = {
        ids: this.multipleSelection,
      };
      delContract(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getlist();
        }
      });
    },
    handleCurrentChange(e){
      this.config.pageNum=e
      this.getlist();
    },
    getlist() {
      findContractList(this.config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    AddEnter() {
      this.$router.push({
        path: "/AddEnter",
      });
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
};
</script>

<style lang="scss" scoped>
.enter {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .content {
      padding: 20px;
      background: #fff;
      min-height: 660px;
      margin-top: 20px;
      border: 1px solid #ccc;
    }
  }
}
.title {
  text-align: center;
  margin: 20px auto;
  font-size: 20px;
}
.Line {
  text-align: center;
  margin: 0 auto;
  height: 1px;
  background: #ccc;
}
.btn {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
</style>